<template>
  <div class='report-page'>
    <div class='actions d-flex justify-end align-center mb-20'>
      <div class='d-flex'>
        <a-input class='mr-15' style='width: 350px;' placeholder='Wallet address...' v-model='query.walletAddress' @change='onChangeQuery' />
        <a-input-number class='mr-15' style='width: 200px;' placeholder=' AVG last 24 hour >=...' v-model='query.nftAvg'
                        @change='onChangeQuery' />
      </div>
      <div class='d-flex'>
        <a-button @click='fetchData'>
          <a-icon type='sync' />
          Refresh
        </a-button>
      </div>
    </div>
    <a-tabs type='card' @change='onChangeTab' v-model='tab'>
      <a-tab-pane :key='1' tab='Map 1'>
        <reports-table-all-map :query='query' :data='data' :loading='loading' ref='reportTableAllMap' />
      </a-tab-pane>
      <a-tab-pane :key='2' tab='Map 2'>
        <reports-table-all-map :query='query' :data='data' :loading='loading' ref='reportTableAllMap' />
      </a-tab-pane>
      <a-tab-pane :key='3' tab='Map 3'>
        <reports-table-all-map :query='query' :data='data' :loading='loading' ref='reportTableAllMap' />
      </a-tab-pane>
      <a-tab-pane :key='4' tab='Map 4'>
        <reports-table-all-map :query='query' :data='data' :loading='loading' ref='reportTableAllMap' />
      </a-tab-pane>
      <a-tab-pane :key='5' tab='Map 5'>
        <reports-table-all-map :query='query' :data='data' :loading='loading' ref='reportTableAllMap' />
      </a-tab-pane>
      <a-tab-pane :key='0' tab='All map'>
        <reports-table-all-map :query='query' :data='data' :loading='loading' ref='reportTableAllMap' />
      </a-tab-pane>
      <a-button slot='tabBarExtraContent' type='success' @click='onExport' :loading='loadingExcel'>
        Export excel
      </a-button>
    </a-tabs>
  </div>
</template>

<script>
import ReportsService from '@/services/app/reports.service'
import {exportExcel} from '@/helpers/common'

export default {
  name: 'Report',
  components: {
    ReportsTableAllMap: () => import('@/components/Pages/Reports/ReportsTableAllMap')
  },
  data() {
    return {
      breadcrumb: [
        {
          path: '/report',
          label: 'List reports'
        }
      ],
      query: {
        walletAddress: null,
        nftAvg: 22
      },
      tab: 1,
      data: [],
      loading: false,
      loadingExcel: false
    }
  },
  computed: {
    avgPoint() {
      let output = 0
      if (this.tab === 1) {
        output = 22
      } else if (this.tab === 2) {
        output = 38
      } else if (this.tab === 3) {
        output = 66
      } else if (this.tab === 4) {
        output = 120
      } else if (this.tab === 5) {
        output = 206
      }
      return output
    },
    sqlQuery() {
      let fitlerQuery = ''
      if (this.query.walletAddress && this.query.nftAvg) {
        fitlerQuery = ` WHERE wallet_address IN (${this.query.walletAddress.split(',').map(item => `'${item.trim()}'`)}) AND sum_reward_24 / count_pet_24 >= ${this.query.nftAvg}`
      } else if (this.query.walletAddress) {
        fitlerQuery = ` WHERE wallet_address IN (${this.query.walletAddress.split(',').map(item => `'${item.trim()}'`)})`
      } else {
        fitlerQuery = ` WHERE sum_reward_24 / count_pet_24 >= ${this.query.nftAvg || 0}`
      }
      if (this.tab === 0) {
        const selectQuery = 'SELECT user_id, wallet_address, sum(count_pet_1) count_pet_1, sum(sum_reward_1) sum_reward_1, sum(count_pet_12) count_pet_12, sum(sum_reward_12) sum_reward_12, sum(count_pet_24) count_pet_24, sum(sum_reward_24) sum_reward_24, sum(count_pet_48)count_pet_48, sum(sum_reward_48) sum_reward_48, sum(count_pet_72) count_pet_72, sum(sum_reward_72) sum_reward_72, sum(count_pet) count_pet, sum(sum_reward) sum_reward FROM report_check_earn'
        return `${selectQuery}${fitlerQuery} GROUP BY user_id, wallet_address`
      } else {
        const selectQuery = 'SELECT user_id, wallet_address, count_pet_1, sum_reward_1, count_pet_12, sum_reward_12, count_pet_24, sum_reward_24, count_pet_48, sum_reward_48, count_pet_72, sum_reward_72, count_pet, sum_reward FROM report_check_earn'
        // if (!this.query.nftAvg) {
        //   fitlerQuery = ` WHERE sum_reward_24 / count_pet_24 >= ${this.query.nftAvg || 0}`
        // }
        return `${selectQuery}${fitlerQuery} AND map = ${this.tab}`
      }
    }
  },
  created() {
    this.setBreadcrumb(this.breadcrumb)
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    onChangeTab(key) {
      this.tab = key
      this.query.nftAvg = this.avgPoint
      this.fetchData()
    },
    onChangeQuery() {
      if (this.timeout) {
        clearTimeout(this.timeout)
        this.timeout = null
      }
      this.timeout = setTimeout(() => {
        this.fetchData()
      }, 800)
    },
    fetchData() {
      this.loading = true
      const payload = {
        query: this.sqlQuery
      }
      ReportsService.getList(payload).then(res => {
        if (res.code === 1) {
          this.data = res.data ? [...res.data].map(item => {
            return {
              ...item,
              sum_reward_1: item.sum_reward_1 ? item.sum_reward_1.toFixed(5) : 0,
              sum_reward_12: item.sum_reward_12 ? item.sum_reward_12.toFixed(5) : 0,
              sum_reward_24: item.sum_reward_24 ? item.sum_reward_24.toFixed(5) : 0,
              sum_reward_48: item.sum_reward_48 ? item.sum_reward_48.toFixed(5) : 0,
              sum_reward_72: item.sum_reward_72 ? item.sum_reward_72.toFixed(5) : 0,
              sum_reward: item.sum_reward ? item.sum_reward.toFixed(5) : 0,
              count_pet_1: item.count_pet_1 ? item.count_pet_1.toFixed(5) : 0,
              count_pet_12: item.count_pet_12 ? item.count_pet_12.toFixed(5) : 0,
              count_pet_24: item.count_pet_24 ? item.count_pet_24.toFixed(5) : 0,
              count_pet_48: item.count_pet_48 ? item.count_pet_48.toFixed(5) : 0,
              count_pet_72: item.count_pet_72 ? item.count_pet_72.toFixed(5) : 0,
              count_pet: item.count_pet ? item.count_pet.toFixed(5) : 0,
              avg_1: item.sum_reward_1 ? (item.sum_reward_1 / item.count_pet_1).toFixed(5) : 0,
              avg_12: item.sum_reward_12 ? (item.sum_reward_12 / item.count_pet_12).toFixed(5) : 0,
              avg_24: item.sum_reward_24 ? (item.sum_reward_24 / item.count_pet_24).toFixed(5) : 0,
              avg_48: item.sum_reward_48 ? (item.sum_reward_48 / item.count_pet_48).toFixed(5) : 0,
              avg_72: item.sum_reward_72 ? (item.sum_reward_72 / item.count_pet_72).toFixed(5) : 0,
              avg: item.sum_reward ? (item.sum_reward / item.count_pet).toFixed(5) : 0
            }
          }) : []
        }
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    },
    onExport() {
      this.loadingExcel = true
      const payload = {
        query: this.sqlQuery
      }
      const params = {
        sheetName: `map_${this.tab || 'all'}.xlsx`
      }
      ReportsService.exportList(payload, params).then(res => {
        if (res) {
          // exportExcel(res, `map_${this.tab || 'all'}.xlsx`)
          let newBlob = new Blob([res], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" });

          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
          }

          const data = window.URL.createObjectURL(newBlob);

          let link = document.createElement('a');
          link.href = data;
          link.download = `map_${this.tab || 'all'}`;
          // this is necessary as link.click() does not work on the latest firefox
          link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

          setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            link.remove();
          }, 100);
        } else {
          this.$notification.error({
            message: 'Something went wrong'
          })
        }
        this.loadingExcel = false
      }).catch(err => {
        this.$notification.error({
          message: 'Something went wrong'
        })
        this.loadingExcel = false
      })
    }
  }
}
</script>

<style scoped lang='scss'>
.report-page {
  padding: 24px;

  ::v-deep {
    .ant-tabs-bar {
      margin-bottom: 16px;
      border-bottom: 0;
    }
  }

  ::v-deep .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    margin: 0;
    border: none;
    background-color: transparent;
    padding: 0 24px;

    &.ant-tabs-tab-active {
      background-color: #ffffff;
      color: #007bb4;
      border-radius: 8px;
    }
  }
}
</style>
